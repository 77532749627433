import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { updateCheckoutForm, validateForm } from 'redux/actions'
import { validate } from 'utils/validator'

import './formularios.css'
import '../../App.css'
import '../../index.css'

function DeliveryContact({ title, subtitle }) {
    const dispatch = useDispatch();

    const renderForm = [
        [{ value: 'NOMBRE', placeholder: 'Nombres' }, { value: 'APELLIDO', placeholder: 'Apellido' }],
        [{ value: 'EMAIL', placeholder: 'Dirección de correo electrónico' }, { value: 'TELEFONO', placeholder: 'Teléfono' }],
    ]

    //Estados globales
    const state = useSelector((state) => state);
    const { clientInfo, logged, formCheckout, validations } = state;

    // Verificamos si es usuario logeado o no y traemos su info.
    const [form, setForm] = useState(formCheckout.CONTACT_FORM)
    const [errors, setErrors] = useState({
        NOMBRE: null,
        COD_POSTAL: null,
        APELLIDO: null,
        EMAIL: null,
        TELEFONO: null
    })

    //Métodos
    const onChangeValue = (e) => {
        let newValue = e.target.value

        // if (e.target.name === 'TELEFONO') {
        //     // Si el campo es "telefono" y el valor no comienza con +52, concatena +52 al inicio
        //     if (!newValue.startsWith('+52')) {
        //       newValue = '+52' + newValue;
        //     }
        //   }
    
        setForm({ ...form, [e.target.name]: newValue }) //Actualiza el valor ingresado al formulario
    }
    const validateInput = (e) => {
        dispatch(validateForm('CONTACT_FORM', validate(e.target))) //Actualiza el estado global del campo
    }

    //Actualiza el formulario global
    useEffect(() => {
        dispatch(updateCheckoutForm({ CONTACT_FORM: form }))
    }, [form]);

    useEffect(() => {
        setErrors({
            ...errors,
            NOMBRE: validations.CONTACT_FORM.NOMBRE ? validations.CONTACT_FORM.NOMBRE : null,
            COD_POSTAL: validations.CONTACT_FORM.COD_POSTAL ? validations.CONTACT_FORM.COD_POSTAL : null,
            APELLIDO: validations.CONTACT_FORM.APELLIDO ? validations.CONTACT_FORM.APELLIDO : null,
            EMAIL: validations.CONTACT_FORM.EMAIL ? validations.CONTACT_FORM.EMAIL : null,
            TELEFONO: validations.CONTACT_FORM.TELEFONO ? validations.CONTACT_FORM.TELEFONO : null,
        })

    }, [validations?.CONTACT_FORM])

    useEffect(() => {
        if (logged) {
            setForm({
                NOMBRE: clientInfo.NOMBRE ? clientInfo.NOMBRE : '',
                APELLIDO: clientInfo.APELLIDO ? clientInfo.APELLIDO : '',
                EMAIL: clientInfo.EMAIL ? clientInfo.EMAIL : '',
                TELEFONO: clientInfo.TELEFONO ? clientInfo.TELEFONO : '',
                //COD_POSTAL: clientInfo.FACT_COD_POSTAL ? clientInfo.FACT_COD_POSTAL : '',
            })
        } else {
            setForm({
                NOMBRE: '',
                APELLIDO: '',
                EMAIL: '',
                TELEFONO: '',
            })
        }
    }, [clientInfo])

    return (
        <Form.Group controlId="contact-form">
            {title && <h3 className="title-h3">{title}</h3>}
            {subtitle && <p className="title-description">{subtitle}</p>}
            {
                logged ?
                    <Container>
                        {/*<Row className="address-row" style={{width:'50%'}}>
                            <Col md={true}>
                                <p className="address-input"><span>C.P.: </span>{form.COD_POSTAL}</p>
                                {errors.COD_POSTAL ?
                                    <p className="address-input-error">{errors.COD_POSTAL}</p>
                                    : null
                                }
                            </Col>
                        </Row>*/}
                        <Row className="address-row">
                            <Col md={true}>
                                <p className="address-input"><span>Nombre: </span> {form.NOMBRE}</p>
                                {errors.NOMBRE ?
                                    <p className="address-input-error">{errors.NOMBRE}</p>
                                    : null
                                }
                            </Col>
                            <Col lg={true}>
                                <p className="address-input"><span>Apellido: </span>{form.APELLIDO}</p>
                                {errors.APELLIDO ?
                                    <p className="address-input-error">{errors.APELLIDO}</p>
                                    : null
                                }
                            </Col>
                        </Row>
                        <Row className="address-row">
                            <Col md={true}>
                                <p className="address-input"><span>Teléfono: </span>{form.TELEFONO}</p>
                                {errors.TELEFONO ?
                                    <p className="address-input-error">{errors.TELEFONO}</p>
                                    : null
                                }
                            </Col>
                            <Col md={true}>
                                <p className="address-input"><span>Email: </span>{form.EMAIL}</p>
                                {errors.EMAIL ?
                                    <p className="address-input-error">{errors.EMAIL}</p>
                                    : null
                                }
                            </Col>
                        </Row>
                    </Container>
                    :
                    renderForm.map(aRows =>
                        <Row className="address-row">
                            {
                                aRows.map(oColumn =>
                                    <Col lg={true}>
                                        <Form.Control
                                            type="text"
                                            isInvalid={validations.CONTACT_FORM[oColumn.value]}
                                            required={true}
                                            validations={JSON.stringify({
                                                minSize: 1
                                            })}
                                            name={oColumn.value}
                                            placeholder={oColumn.placeholder || oColumn.value}
                                            value={form[oColumn.value]}
                                            onBlur={validateInput}
                                            onChange={onChangeValue}
                                            className='golden-input address-input' />
                                        <Form.Control.Feedback className="input-feedback" type='invalid'>{validations.CONTACT_FORM[oColumn.value]}</Form.Control.Feedback>
                                    </Col>
                                )
                            }
                        </Row>
                    )
            }



        </Form.Group>
    )
}

export default DeliveryContact