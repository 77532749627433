import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Alert } from 'react-bootstrap'

import HeroBanner from '../../common/hero-banner.common'
import BillingInfo from '../../common/formulario/BillingInfo'
import PencilIcon from '../../common/pencil.common'
import UserInformation from '../../components/mi-cuenta/UserInformation'
import ClientDir from '../../components/mi-cuenta/ClientDir'
import { Plus, XLg } from 'react-bootstrap-icons';
import { getBannerImg } from 'utils/getImages'
import MiCuentaMenu from '../../components/mi-cuenta/MiCuentaMenu'
import '../../index.css'
import '../../App.css'
import '../../common/formulario/formularios.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { addClientDir, deleteClientDir, getClientDir, updateCheckoutForm, updateClientDir, updateLoggedClient, validateForm } from 'redux/actions'
import Swal from 'sweetalert2'
import { checkForm, validarCampos } from 'utils/validator'
import CrossSell from 'common/cross-sell/cross-sell.common'
import FavoritosCarrousel from './FavoritosCarrousel'
import urlSubcuenta from 'utils/urlSubcuenta'

function MisDatos() {
  const dispatch = useDispatch();
  const clearForm = {
    DIRECCION: '',
    COLONIA: '',
    NUMERO_EXT: '',
    NUMERO_INT: '',
    ESTADO: '',
    CIUDAD: ''
  };
  const state = useSelector((state) => state);
  const { clientInfo, validations, formCheckout, acompañamientos, logged } = state;
  const [editing, setEditingForm] = useState({ CLIENT_FORM: false, BILLING_FORM: false });
  const [showErrors, setShowError] = useState({ CLIENT_FORM: false, BILLING_FORM: false });
  const [saved, setSaved] = useState(true);
  const [direcciones, setDirecciones] = useState([]);
  const [newDirecciones, setNewDirecciones] = useState([]);

  const handleEdit = (sForm) => {
    dispatch(validateForm('global', { [sForm]: true }))
    let oValidatesForm = validarCampos(formCheckout[sForm])
    let isValid = checkForm(oValidatesForm)

    // Además, valido si el codigo postal ingresado (si es que es un formulario que incluya CP, sea válido)
    if (sForm.includes('DIRECCION')) {
      isValid &= checkForm({ ...oValidatesForm, ID_DIRECCION: null })
      isValid &= state[`postalCodeCheck--CP--${sForm}`]
    }

    if (editing[sForm]) {
      dispatch(validateForm([sForm], oValidatesForm))
      setShowError({ ...showErrors, [sForm]: !isValid });
      if (!isValid) {
        return false
      }
      setEditingForm({ ...editing, [sForm]: false });

    } else {
      setEditingForm({ ...editing, [sForm]: !editing[sForm] });
    }
    return true
  }

  const _clearForm = () => {
    let index = direcciones.length + newDirecciones.length,
    // let index = newDirecciones.length - 1,
      newId = `DIRECCION${index}_FORM`;
    //Elimina el objeto para validar el ultimo form
    dispatch(validateForm('global', { [newId]: false }));

    //Seteo editing false
    setEditingForm({ ...editing, [newId]: true })

    //Actualizo formulario global
    dispatch(updateCheckoutForm({ [`DIRECCION${newId}_FORM`]: clearForm }))
    dispatch(validateForm({ [newId]: clearForm }))
  }
  const handleDeleteDir = (e) => {
    e.preventDefault()
    Swal.fire({
      title: '¿Estás seguro que deseas eliminar esta dirección?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: `Cancelar`,
      confirmButtonColor: '#E5CB65',
      background: '#333',
      color: '#FFF'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteClientDir(clientInfo.UUID, clientInfo.EMAIL, e.target.value))
        _clearForm();
        Swal.fire('¡Hecho!', 'Dirección eliminada correctamente', 'success')
      }
    })
  }
  const handleAddDir = () => {
    let index = direcciones.length + newDirecciones.length,
      newId = `DIRECCION${index}_FORM`;

    //Agrega el objeto para validar
    // dispatch(validateForm('global', { [newId]: false }));

    //Seteo editing true
    setEditingForm({ ...editing, [newId]: true })

    //Agrega objeto para el formulario
    setNewDirecciones(e => [...e, clearForm]);
  }
  const onDeleteNewDir = (idx) => {
    let index = idx - direcciones.length,
      d = [];
    //Elimina el objeto para validar
    dispatch(validateForm('global', { [`DIRECCION${idx}_FORM`]: false }));

    //Actualizo los formularios
    for (let i = 0; i < newDirecciones.length; i++) {
      let formID = `DIRECCION${direcciones.length + i}_FORM`;
      //Agrega los elementos al array resultante
      if (i !== index) {
        d.push(formCheckout[formID])
        //Se desplazan los datos ingresados en las direcciones un lugar hacia atras
        if (i > index) {
          dispatch(updateCheckoutForm({ [`DIRECCION${direcciones.length + i - 1}_FORM`]: i < 3 ? formCheckout[formID] : clearForm }))
        }
      }
    }
    setNewDirecciones(d);
  }

  const _validate = () => {
    let aValidations = [
      validations.global.CLIENT_FORM ? 'CLIENT_FORM' : null,
      validations.global.BILLING_FORM ? 'BILLING_FORM' : null,
      validations.global.DIRECCION0_FORM ? 'DIRECCION0_FORM' : null,
      validations.global.DIRECCION1_FORM ? 'DIRECCION1_FORM' : null,
      validations.global.DIRECCION2_FORM ? 'DIRECCION2_FORM' : null,
    ].filter(e => !!e),
      oNewErrors = {},
      isValid = true;

    if (aValidations.length < 1) {
      Swal.fire({
        title: 'No se realizaron modificaciones aún. Por favor, edita los campos antes de guardar.',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
        cancelButtonText: `Cancelar`,
        confirmButtonColor: '#E5CB65',
        background: '#333',
        color: '#FFF',
      })
      return false
    } else {
      // console.log("AAAAAA", aValidations)
    }
    //Valido los formularios
    aValidations.forEach(sForm => {
      let oValidateForm = validarCampos(formCheckout[sForm])
      dispatch(validateForm(sForm, oValidateForm))
      let currentIsValid = checkForm(oValidateForm);


      //Valida el codigo postal si es una direccion
      if (sForm.includes("DIRECCION")) {
        currentIsValid &= state[`postalCodeCheck--CP--${sForm}`]
      }
      oNewErrors[sForm] = !currentIsValid;
      if (!currentIsValid) {
        isValid = false
      }
    });
    setShowError({ ...showErrors, ...oNewErrors });
    return isValid;
  }

  const _submitConfirm = (result) => {
    if (result.isConfirmed) {
      if (validations.global.BILLING_FORM) {
        //Actualiza los datos del cliente
        dispatch(updateLoggedClient(clientInfo.UUID, clientInfo.EMAIL, { ...formCheckout.BILLING_FORM, COD_POSTAL: formCheckout.BILLING_FORM.FACT_COD_POSTAL }))
      }

      if (validations.global.CLIENT_FORM) {
        //Actualiza los datos del cliente
        dispatch(updateLoggedClient(clientInfo.UUID, clientInfo.EMAIL, { ...formCheckout.CLIENT_FORM }))
      }


      //Actualiza las direcciones
      direcciones.forEach((_, idx) => {
        if (validations.global[`DIRECCION${idx}_FORM`]) {
          dispatch(updateClientDir(clientInfo.UUID, clientInfo.EMAIL, { ...formCheckout[`DIRECCION${idx}_FORM`], COD_POSTAL: state[`CP--DIRECCION${idx}_FORM`] }))
        }
      });

      //Agrega las direcciones nuevas
      newDirecciones.forEach((_, idx) => {
        if (validations.global[`DIRECCION${direcciones.length + idx}_FORM`]) {
          dispatch(addClientDir(clientInfo.UUID, clientInfo.EMAIL, { ...formCheckout[`DIRECCION${direcciones.length + idx}_FORM`], COD_POSTAL: state[`CP--DIRECCION${direcciones.length + idx}_FORM`] }))
          setEditingForm({...editing, [`DIRECCION${direcciones.length + idx}_FORM`]: false})
        }
      });

      //Obtengo las direcciones
      dispatch(getClientDir(clientInfo.UUID, clientInfo.EMAIL));

      //Avisa que actualice el arreglo de direcciones
      setSaved(false);

      Swal.fire('Cambios guardados correctamente', '', 'success')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    //Valida los formularios
    if (!_validate()) {
      return false
    }
    Swal.fire({
      title: '¿Deseas guardar los cambios realizados?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: `Cancelar`,
      confirmButtonColor: '#E5CB65',
      background: '#333',
      color: '#FFF',
    }).then(_submitConfirm)
  }

  useEffect(() => {
    // Si no existe el array de direccion Ó si el array de direcciones está vacio Y si el array de nuevas direcciones esta vacío
    if ((!clientInfo.DIRECCIONES || clientInfo.DIRECCIONES.length < 1) && newDirecciones.length < 1) {
      handleAddDir();
    } else {
      // Si NO esta guardado es decir saved = false
      if (!saved) {
        setNewDirecciones([]); // Setea las nuevas direcciones a un array vacio
        _clearForm(); 
        setSaved(true);
      }
    }
    if (!logged) {
      window.location.href = urlSubcuenta() + '/login/';
    } else {
      if (clientInfo.DIRECCIONES) {
        setDirecciones(clientInfo.DIRECCIONES);
      }
    }
  }, [clientInfo.DIRECCIONES]);

  // useEffect(() => {
  //   console.log(editing)
  // }, [editing]);
  // useEffect(() => {
  //   console.log("Nuevas direcciones:", newDirecciones)
  // }, [editing]);
  // useEffect(() => {
  //   console.log("Direcciones", direcciones)
  // }, [editing]);
  
  return (
    <div className='cuenta-datos'>
      <div className="checkout-banner">
        <HeroBanner title='Datos personales' subtitle={false} backgroundImg={getBannerImg("MiCuenta")}></HeroBanner>
      </div>

      <section className="gradient-bg">
        <Stack>
          <MiCuentaMenu />
          <Stack orientation='vertical' className='container checkout-body' gap={4}>
            <Stack direction='horizontal' className='icon-btn-cont'>
              <h3 className="title-h3">Datos personales</h3>
              <PencilIcon onClick={() => handleEdit('CLIENT_FORM')} />
            </Stack>
            <Alert show={showErrors.CLIENT_FORM} variant='danger'>Por favor, revise los datos antes de continuar.</Alert>
            <UserInformation editMode={editing.CLIENT_FORM} />

            {
              direcciones.length ?
                direcciones.map((dir, index) => {
                  let newId = `DIRECCION${index}_FORM`;
                  return (
                    <>
                      <Stack direction='horizontal' className='icon-btn-cont'>
                        <h3 className="title-h3">Dirección {index + 1}</h3>
                        <PencilIcon onClick={() => handleEdit(newId)} />
                        {
                          !editing[newId] &&
                          <Stack>
                            <button onClick={handleDeleteDir} value={dir.ID_DIRECCION} className='delete-dir-btn'>Eliminar Dirección</button>
                          </Stack>
                        }
                      </Stack>
                      <Alert show={!!showErrors[newId]} variant='danger'>Por favor, revise los datos antes de continuar.</Alert>
                      <ClientDir index={index} editMode={!!editing[newId]} item={dir} />
                    </>
                  )
                }) : null
            }

            {
              newDirecciones ?
                newDirecciones.map((dir, idx) => {
                  var index = direcciones.length + idx,
                    newId = `DIRECCION${index}_FORM`;

                  return (
                    <>
                      <Stack className='dir-headers-cont' direction='horizontal'>
                        <Stack direction='horizontal' className='icon-btn-cont'>
                          <h3 className="title-h3">Nueva dirección {index + 1}</h3>
                          <button className='icon-btn' onClick={() => onDeleteNewDir(index)}><XLg /></button>
                        </Stack>
                      </Stack>
                      <Alert show={!!showErrors[newId]} variant='danger'>Por favor, revise los datos antes de continuar.</Alert>
                      <ClientDir index={index} editMode={true} item={dir} isNew={true} />
                      {/* <NewClientDir
                      propiedades={{ globalForm, setGlobalForm, globalErrors, setGlobalErrors }}
                      onDelete={() => onDeleteDir(dir)}
                      id={index + 1}
                      title={`Agregar dirección ${index + 1}`}
                    /> */}
                    </>
                  )
                })
                : null
            }

            {
              (direcciones.length + newDirecciones.length) < 3 &&
              <Stack direction='horizontal' className='icon-btn-cont'>
                <h3 className="title-h3">Agregar dirección</h3>
                <button style={{ transform: 'scale(1.5) translateY(-6px)' }} className='icon-btn' onClick={() => handleAddDir()}><Plus /></button>
              </Stack>
            }
            <BillingInfo title='Datos de facturación' editMode={editing.BILLING_FORM} />
            <Alert show={showErrors.BILLING_FORM} variant='danger'>Por favor, revise los datos antes de continuar.</Alert>

            <Stack style={{ alignItems: 'center' }}>
              <button className="btn-dorado btn-submit" onClick={handleSubmit}>Guardar</button>
            </Stack>
          </Stack>
        </Stack>
      </section>
      <div>
        <FavoritosCarrousel />
      </div>
      <div>
        <CrossSell titulo='Te puede interesar' subtitulo='Descubre un par de tips y recetas para llevar tu parrilla a otro nivel' items={acompañamientos} backgroundImage={true} />
      </div>
    </div>
  )
}

export default MisDatos