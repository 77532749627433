//import axios from "axios";
import actionTypes from "./actionTypes"
import http from "../../utils/http"
import { toast } from "react-toastify";

// const urlPathname = window.location.pathname
// var URL = ""
// if (urlPathname.includes("host")) {
//     URL = process.env.REACT_APP_URL
// } else {
//     URL = `/apikuo-test/`;
// }

// **** RESET_FORM_CHECKOUT **** 
export function resetFormCheckout() {
    return (dispatch) => {
        dispatch({ type: actionTypes.RESET_FORM_CHECKOUT });
    };
}

export function resetCardForm() {
    return (dispatch) => {
        dispatch({ type: actionTypes.RESET_CARD_FORM });
    };
}

export function resetTrigger() {
    return (dispatch) => {
        dispatch({ type: actionTypes.CLEAR_RESET_TRIGGER });
    };
}

// **** UPDATE_GLOBAL_STATE ****
export function updateGlobalState(object) {
    return async (dispatch) => {
        try {
            return dispatch({ type: actionTypes.UPDATE_GLOBAL_STATE, payload: object });
        }
        catch (e) {
            console.log(e);
        }
    };
}

// **** GET_LOGGED_STATE y GET_LOGGED_CLIENT ó POST_LOGGED_CLIENT ****
export function getLoggedClient() {
    return async (dispatch) => {
        try {
            dispatch(setLoader(true))
            const response = await http.get(`/selectos_keken_uua/userinfo`);
            console.log('Primer request', response)
            if (response.data.sub_idp) {
                const userData = {
                    UUID: response.data.sub_idp,
                    NOMBRE: response.data.given_name,
                    APELLIDO: response.data.family_name,
                    EMAIL: response.data.email
                }

                try {
                    const { data } = await http.get(`/apikuo-pri/clientes.xsjs?UUID=${response.data.sub_idp}&EMAIL=${response.data.email}`);
                    console.log("Segundo request", { data })

                    if (Object.keys(data.result).length > 0) {
                        // const cartFromLocalStorage = getCartItems();
                        // if (cartFromLocalStorage && cartFromLocalStorage.length > 0) {
                        //     try {
                        //         const response = await http.get(`/apikuo-pri/carrito.xsjs?UUID=${userData.UUID}&EMAIL=${userData.EMAIL}`);
                        //         const updatedCart = mergeCarts(cartFromLocalStorage, response.data.data)
                        //         dispatch({ type: actionTypes.GET_CLIENT_CART, payload: updatedCart });
                        //     }
                        //     catch (e) {
                        //         console.log(e);
                        //     }
                        // }
                        dispatch({ type: actionTypes.GET_LOGGED_CLIENT, payload: data.result });
                    }

                    else {
                        try {
                            const config = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: `/apikuo-pri/clientes.xsjs?UUID=${userData.UUID}&EMAIL=${userData.EMAIL}`,
                                headers: {
                                    'Content-Type': 'application/json;charset=UTF-8',
                                    'X-XSS-Protection': '1; mode = block',
                                    'X-Content-Type-Options': 'nosniff',
                                    'X-Frame-Options': 'SAMEORIGIN',
                                    'Content-Security-Policy': 'self',
                                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                                },
                                data: JSON.stringify({
                                    NOMBRE: userData.NOMBRE,
                                    APELLIDO: userData.APELLIDO,
                                    EMAIL: userData.EMAIL
                                })
                            }

                            dispatch({
                                type: actionTypes.GET_LOGGED_CLIENT,
                                payload: {
                                    NOMBRE: userData.NOMBRE,
                                    APELLIDO: userData.APELLIDO,
                                    EMAIL: userData.EMAIL
                                }
                            });

                            await http.request(config)
                            console.log("Tercer response (POST)");
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }
                catch (e) {
                    console.log(e);
                }
                dispatch(setLoader(false))
                return dispatch({
                    type: actionTypes.GET_LOGGED_STATE, payload: true
                });
            } else {
                dispatch(setLoader(false))
                return dispatch({
                    type: actionTypes.GET_LOGGED_STATE, payload: false
                });
            }
        }
        catch (e) {
            dispatch(setLoader(false))
            console.log(e);
        }
    };
}

// **** UPDATE_LOGGED_CLIENT ****
export function updateLoggedClient(UUID, EMAIL, BODY_OBJ) {
    return async (dispatch) => {
        try {

            // Muestra mensaje de éxito
            const config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: `/apikuo-pri/clientes.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode = block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                },
                data: JSON.stringify(BODY_OBJ)
            }

            //const response = 
            await http.request(config)

            toast.success('Información actualizada correctamente.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });
            return dispatch({ type: actionTypes.UPDATE_LOGGED_CLIENT, payload: BODY_OBJ });
        }
        catch (e) {
            // Muestra mensaje de error
            toast.error('Error al actualizar la información.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });
            console.log(e);
        }
    };
}

// **** GET_CLIENT_DIR ****
export function getClientDir(UUID, EMAIL) {
    return async (dispatch) => {
        try {
            const response = await http.get(`/apikuo-pri/direcciones.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`);
            return dispatch({ type: actionTypes.GET_CLIENT_DIR, payload: response.data.result });
        }
        catch (e) {
            console.log(e);
        }
    };
}

// **** POST_CLIENT_DIR ****
export function addClientDir(UUID, EMAIL, BODY_OBJ) {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `/apikuo-pri/direcciones.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode = block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                },
                data: JSON.stringify(BODY_OBJ)
            }

            const response = await http.request(config)
            // Muestra mensaje de éxito
            toast.success('Nueva dirección añadida correctamente.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });
            return dispatch({ type: actionTypes.ADD_CLIENT_DIR, payload: response.data.result.data });
        }
        catch (e) {
            // Muestra mensaje de error
            toast.error('Error al añadir la dirección.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });
            console.log(e);
        }
    };
}

// **** UPDATE_CLIENT_DIR ****
export function updateClientDir(UUID, EMAIL, BODY_OBJ) {
    return async (dispatch) => {
        try {
            const config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: `/apikuo-pri/direcciones.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode = block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                },
                data: JSON.stringify(BODY_OBJ)
            }
            //const response = 
            await http.request(config)
            // Muestra mensaje de éxito
            toast.success('Dirección actualizada correctamente.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });
            return dispatch({ type: actionTypes.UPDATE_CLIENT_DIR, payload: BODY_OBJ });
        }
        catch (e) {
            // Muestra mensaje de error
            toast.error('Error al actualizar la dirección guardada.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });
            console.log(e);
        }
    };
}

// **** DELETE_CLIENT_DIR ****
export function deleteClientDir(UUID, EMAIL, ID_DIRECCION) {
    return async (dispatch) => {
        try {
            const config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `/apikuo-pri/direcciones.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode = block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                },
                data: JSON.stringify({
                    ID_DIRECCION: ID_DIRECCION
                })
            }
            //const response = 
            await http.request(config)
            return dispatch({ type: actionTypes.DELETE_CLIENT_DIR, payload: ID_DIRECCION });
        }
        catch (e) {
            console.log(e);
        }
    };
}


// **** GET_CLIENT_FAV ****
export function getClientFav(UUID, EMAIL) {
    return async (dispatch) => {
        try {
            const { data } = await http.get(`/apikuo-pri/favoritos.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`);
            // data = array de objetos con id_producto, uuid, fecha
            return dispatch({ type: actionTypes.GET_CLIENT_FAV, payload: data.result });
        }
        catch (e) {
            console.log(e);
        }
    };
}

// **** ADD_CLIENT_FAV ****
export function addClientFav(UUID, EMAIL, ID_PRODUCTO) {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `/apikuo-pri/favoritos.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode = block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                },
                data: JSON.stringify({
                    ID_PRODUCTO: ID_PRODUCTO
                })
            }

            //const response = 
            await http.request(config)
            // data = array de objetos con id_producto, uuid, fecha
            return dispatch({ type: actionTypes.ADD_CLIENT_FAV, payload: ID_PRODUCTO });
        }
        catch (e) {
            console.log(e);
        }
    };
}

// **** DELETE_CLIENT_FAV ****
export function deleteClientFav(UUID, EMAIL, ID_PRODUCTO) {
    const payload = JSON.stringify({
        ID_PRODUCTO: ID_PRODUCTO
    })

    return async (dispatch) => {
        try {
            //const response = 
            await http.request({
                method: 'DELETE',
                url: `/apikuo-pri/favoritos.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                data: payload
            });

            return dispatch({ type: actionTypes.DELETE_CLIENT_FAV, payload: ID_PRODUCTO });
        } catch (e) {
            console.log(e);
        }
    };
}

// **** GET_CLIENT_CART ****
export function getClientCart(UUID, EMAIL) {
    return async (dispatch) => {
        try {
            const response = await http.get(`/apikuo-pri/carrito.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`);
            // data = { ID_CARRITO: 1, TOTAL: 200, PRODUCTOS: [{ID_PRODUCTO: "T000002", PRECIO: 300}]}

            if (response.data.data.PRODUCTOS.length === 0) {
                // Obtiene los productos del carrito no logueado del localStorage
                const cartFromLocalStorage = JSON.parse(localStorage.getItem('cartItems')) || [];

                if (cartFromLocalStorage && cartFromLocalStorage.length) {
                    // Agrega el campo necesario a los productos del carrito no logueado
                    const promises = cartFromLocalStorage.map(async item => {
                        console.log("Estoy añadiendo items a tu carrito", item);
                        await dispatch(addItemClientCart(UUID, EMAIL, { ID_PRODUCTO: item.ID_PRODUCTO, CANTIDAD: item.CANTIDAD }));
                    });
                    await Promise.all(promises);


                    //Limpiamos el carrito del no logueado.
                    localStorage.removeItem('cartItems');
                    localStorage.removeItem('TOTAL'); // Remover la variable TOTAL del localStorage

                    // dispatch({
                    //     type: actionTypes.CLEAR_CART,
                    //     payload: 0,
                    // });
                }
            } else {
                //Limpiamos el carrito del no logueado.
                localStorage.removeItem('cartItems');
                localStorage.removeItem('TOTAL');

                // Despacha la acción con la respuesta combinada
                return dispatch({ type: actionTypes.GET_CLIENT_CART, payload: response.data.data });
            }
        }
        catch (e) {
            console.log(e);
        }
    };
}

// **** ADD_ITEM_CLIENT_CART ****
export function addItemClientCart(UUID, EMAIL, BODY_OBJ) {
    return async (dispatch) => {
        try {
            const config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: `/apikuo-pri/carrito.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode = block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                },
                data: JSON.stringify(BODY_OBJ)
            }
            const response = await http.request(config)
            // Muestra mensaje de éxito
            toast.success('Producto añadido al carrito', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });

            return dispatch({ type: actionTypes.ADD_ITEM_CLIENT_CART, payload: response.data.data });
        }
        catch (e) {
            // Muestra mensaje de error
            toast.error('Error al añadir un producto al carrito.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });
            console.log(e);
        }
    };
}

// ***** CAMBIAR CANTIDAD DEL PRODUCTO AÑADIDO *****
export function changeItemQuantity(UUID, EMAIL, BODY_OBJ) {
    return async (dispatch) => {
        try {
            const config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: `/apikuo-pri/carrito.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode = block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                },
                data: JSON.stringify(BODY_OBJ)
            }
            const response = await http.request(config)
            // return dispatch({ type: actionTypes.CHANGE_ITEM_QUANTITY, payload: response.data.data });
            return dispatch({ type: actionTypes.CHANGE_ITEM_QUANTITY, payload: BODY_OBJ });
        }
        catch (e) {
            // Muestra mensaje de error por consola
            console.log(e);
        }
    };
}

// **** DELETE_ITEM_CLIENT_CART ****
export function deleteItemClientCart(UUID, EMAIL, ID_PRODUCTO) {
    const payload = JSON.stringify({
        ID_PRODUCTO: ID_PRODUCTO
    });

    return async (dispatch) => {
        try {
            const response = await http.request({
                method: 'DELETE',
                url: `/apikuo-pri/carrito.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                data: payload
            });

            return dispatch({ type: actionTypes.DELETE_ITEM_CLIENT_CART, payload: response.data.data });
        } catch (e) {

            console.log(e);
        }
    };
}

// **** GET PRODUCTOS ****

export function getProductos() {
    return async (dispatch) => {
        try {
            const { data } = await http.get(`/apikuo-public/productos.xsjs?TIPO=PRODUCTO`);
            return dispatch({ type: actionTypes.GET_PRODUCTOS, payload: data.data });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: actionTypes.GET_PRODUCTOS,
                payload: [
                    {
                      "ID_PRODUCTO": "P000001",
                      "ACTIVO": "X",
                      "NOMBRE": "ARRACHERA DE CERDO",
                      "CATEGORIA": "CERDO",
                      "TIPO": "PRODUCTO",
                      "DESCRIPCION": "Uno de los cortes más nobles para cocinar, gracias a su sabor natural y textura suave, es perfecto para asar a la parrilla. La sal y especias son suficientes para realzar sus atributos",
                      "PRECIO": "109.5",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "ARRACHERA_DE_CERDO",
                      "PESO": "1"
                    },
                    {
                      "ID_PRODUCTO": "P000002",
                      "ACTIVO": "X",
                      "NOMBRE": "COSTILLAS ST. LOUIS",
                      "CATEGORIA": "CERDO",
                      "TIPO": "PRODUCTO",
                      "DESCRIPCION": "Se obtienen del centro del costillar del cerdo. Es una costilla larga con carne pegada al hueso lo que le da suavidad y jugosidad. De más rápida cocción que una costilla tradicional, ideal para cocinarse a la parrilla a fuego lento con el marinado de tu elección",
                      "PRECIO": "185",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "COSTILLAS_ST._LOUIS",
                      "PESO": "1"
                    },
                    {
                      "ID_PRODUCTO": "P000003",
                      "ACTIVO": "X",
                      "NOMBRE": "BABY BACK RIBS",
                      "CATEGORIA": "CERDO",
                      "TIPO": "PRODUCTO",
                      "DESCRIPCION": "Son las clásicas costillas cortas con abundante carne, perfectas para preparar a la barbecue estilo americano. Reciben este nombre por ser más pequeñas en comparación a las costillas St. Louis",
                      "PRECIO": "225",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "BABY_BACK_RIBS",
                      "PESO": "1.2"
                    },
                    {
                      "ID_PRODUCTO": "P000004",
                      "ACTIVO": "X",
                      "NOMBRE": "RIB EYE",
                      "CATEGORIA": "CERDO",
                      "TIPO": "PRODUCTO",
                      "DESCRIPCION": "Corte grueso de composición magra por dentro y con grasa exterior, lo que lo hace de gran jugosidad y sabor ya que al cocinarse a la parrilla la grasa se derrite sobre la carne",
                      "PRECIO": "175",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "RIB_EYE",
                      "PESO": "1"
                    },
                    {
                      "ID_PRODUCTO": "P000005",
                      "ACTIVO": "X",
                      "NOMBRE": "PORK BELLY",
                      "CATEGORIA": "CERDO",
                      "TIPO": "PRODUCTO",
                      "DESCRIPCION": "Este corte cuenta con piel y varias capas de carne y grasa, que lo hacen jugoso, blando y de un sabor sin igual Ideal para ahumar en la parrilla a fuego lento",
                      "PRECIO": "170",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "PORK_BELLY",
                      "PESO": "2"
                    },
                    {
                      "ID_PRODUCTO": "P000006",
                      "ACTIVO": "X",
                      "NOMBRE": "RACK DE LOMO REBANADO",
                      "CATEGORIA": "CERDO",
                      "TIPO": "PRODUCTO",
                      "DESCRIPCION": "Corte jugoso y con mucha carne. Es un corte derivado de rebanar el lomo con la costilla, el resultado es una chuleta gruesa con hueso largo que le brinda gran sabor y jugosidad a la carne",
                      "PRECIO": "185",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "RACK_DE_LOMO_REBANADO",
                      "PESO": "1"
                    },
                    {
                      "ID_PRODUCTO": "P000007",
                      "ACTIVO": "X",
                      "NOMBRE": "MEDALLÓN DE LOMO",
                      "CATEGORIA": "CERDO",
                      "TIPO": "PRODUCTO",
                      "DESCRIPCION": "Resultado de rebanar la carne suave y tierna del lomo. Corte magro de rápida cocción, ideal para cocinar al horno, a la parrilla o a la plancha",
                      "PRECIO": "135",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "MEDALLÓN_DE_LOMO",
                      "PESO": "1"
                    },
                    {
                      "ID_PRODUCTO": "P000008",
                      "ACTIVO": "X",
                      "NOMBRE": "FILETE",
                      "CATEGORIA": "CERDO",
                      "TIPO": "PRODUCTO",
                      "DESCRIPCION": "Es un corte muy jugoso, suave y con poca grasa, ideal para hornear o asar a fuego lento en la parrilla",
                      "PRECIO": "140",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "FILETE",
                      "PESO": "1.3"
                    },
                    {
                      "ID_PRODUCTO": "P000009",
                      "ACTIVO": "X",
                      "NOMBRE": "FILETE PARRILLERO",
                      "CATEGORIA": "CERDO",
                      "TIPO": "PRODUCTO",
                      "DESCRIPCION": "Este corte proviene del cuello (papada del cerdo). Es muy suave y jugoso y con un sabor sin igual, además de ser muy exclusivo, ya que solo se obtienen de 2 a 3 kilos por cerdo. La grasa intramuscular con la que cuenta este corte se derrite en la parrilla, lo que lo hace perfecto para asar a fuego indirecto",
                      "PRECIO": "160",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "FILETE_PARRILLERO",
                      "PESO": "1"
                    }
                  ]
            })
        }
    }
}


// **** GET PRODUCTOS HISTORICO ****
export function getProductosHistorico(UUID, EMAIL) {
    return async (dispatch) => {
        try {
            const response = await http.get(`/apikuo-pri/productoshistoricos.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`);
            return dispatch({ type: actionTypes.GET_PRODUCTOS_HISTORICO, payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
}

// **** GET ACOMPAÑAMIENTOS ****
export function getAcompañamientos() {
    return async (dispatch) => {
        try {
            const { data } = await http.get(`/apikuo-public/productos.xsjs?TIPO=ACOMPAÑAMIENTO`);
            return dispatch({ type: actionTypes.GET_ACOMPAÑAMIENTOS, payload: data.data });
        } catch (e) {
            console.log(e);
            return dispatch({
                type: actionTypes.GET_ACOMPAÑAMIENTOS,
                payload: [
                    {
                      "ID_PRODUCTO": "A000001",
                      "ACTIVO": "X",
                      "NOMBRE": "CHISTORRA",
                      "CATEGORIA": "CHORIZOS",
                      "TIPO": "ACOMPAÑAMIENTO",
                      "DESCRIPCION": "Embutido 100% de cerdo elaborado con mezcla de pimentones, ideal para asar y acompañar con queso fundido",
                      "PRECIO": "224.9",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "CHISTORRA",
                      "PESO": "0.35"
                    },
                    {
                      "ID_PRODUCTO": "A000002",
                      "ACTIVO": "X",
                      "NOMBRE": "CHORIZO ESPAÑOL",
                      "CATEGORIA": "CHORIZOS",
                      "TIPO": "ACOMPAÑAMIENTO",
                      "DESCRIPCION": "Tipo Español 100% de cerdo, condimentado con pimienta y otras especias, ideal para acompañar tus cortes",
                      "PRECIO": "121.9",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "CHORIZO_ESPAÑOL",
                      "PESO": "1"
                    },
                    {
                      "ID_PRODUCTO": "A000003",
                      "ACTIVO": "X",
                      "NOMBRE": "CHORIZO ARGENTINO",
                      "CATEGORIA": "CHORIZOS",
                      "TIPO": "ACOMPAÑAMIENTO",
                      "DESCRIPCION": "Chorizo tipo argentino 100% de cerdo, elaborado con especias naturales, ideal para asar y para hacer choripanes",
                      "PRECIO": "196.9",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "CHORIZO_ARGENTINO",
                      "PESO": "0.4"
                    },
                    {
                      "ID_PRODUCTO": "A000004",
                      "ACTIVO": "X",
                      "NOMBRE": "SALCHICHA ASADERA",
                      "CATEGORIA": "CHORIZOS",
                      "TIPO": "ACOMPAÑAMIENTO",
                      "DESCRIPCION": "100% de cerdo, ideal para acompañar tu carne asada",
                      "PRECIO": "88.5",
                      "UNIDAD": "KG",
                      "URL_IMAGEN": "SALCHICHA_ASADERA",
                      "PESO": "0.8"
                    }
                  ]
            })
        }
    }
}

// **** CHECK_POSTAL_CODE **** 
export function checkPostalCode(codPostal, instance) {
    return async (dispatch) => {
        try {
            const { data } = await http.get(`/apikuo-public/codigoPostal.xsjs?COD_POSTAL=${codPostal}`);
            if (data.error) {
                // Muestra mensaje de error
                // toast.error('Error al validar el código postal ingresado.', {
                //     position: toast.POSITION.BOTTOM_LEFT,
                //     autoClose: 3000,
                //     theme: 'dark'
                // });
                return dispatch({ type: actionTypes.CHECK_POSTAL_CODE, payload: { data: [], instance, codPostal } });
            }

            // Muestra mensaje de éxito
            // toast.success('Código postal validado correctamente.', {
            //     position: toast.POSITION.BOTTOM_LEFT,
            //     autoClose: 3000,
            //     theme: 'dark'
            // });

            return dispatch({ type: actionTypes.CHECK_POSTAL_CODE, payload: { data: data.data, instance, codPostal } });
        }
        catch (e) {
            //Muestra mensaje de error
            toast.error('Error al validar el código postal ingresado.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });

            console.log(e);
            // return dispatch({ type: actionTypes.CHECK_POSTAL_CODE, payload: { data: [
            //     {
            //         "ID": 33,
            //         "NOMBRE": "Yucatán (Exp. Santiago)",
            //         "DIRECCION": "Calle 70 # 488, Local 3 X 57 Y 59. Colonia Centro. C.P. 97000, Mérida, Yucatán",
            //         "DIRECCION_MAPA": "20.9701/-89.628967",
            //         "COD_POSTAL": "97000",
            //         "TELEFONO": "(999) 9231831",
            //         "CORREO": "expendio.santiago@keken.com.mx",
            //         "ID_ZONA": 3,
            //         "ACTIVE": 1
            //     },
            //     {
            //         "ID": 1,
            //         "NOMBRE": "Yucatán (Exp. SD San Juan)",
            //         "DIRECCION": "Calle 69 No. 531, entre 62 y 64, col. Centro, Mérida, Yucatán. C.P.",
            //         "DIRECCION_MAPA": "20.9630937/-89.6259838",
            //         "COD_POSTAL": "97000",
            //         "TELEFONO": "999 92 31 832",
            //         "CORREO": "exp.sdsanjuanmid@keken.com.mx",
            //         "ID_ZONA": 3,
            //         "ACTIVE": 0
            //     }
            // ], instance, codPostal } });
        }
    };
}

// **** UPDATE_CHECKOUT_FORM **** 
export function updateCheckoutForm(object) {
    return async (dispatch) => {
        try {
            return dispatch({ type: actionTypes.UPDATE_CHECKOUT_FORM, payload: object });
        }
        catch (e) {
            console.log(e);
        }
    };
}

// **** FORM_VALIDATION **** 
export function validateForm(formName, object) {
    return async (dispatch) => {
        try {
            return dispatch({ type: actionTypes.FORM_VALIDATION, payload: { form: formName, values: object } });
        }
        catch (e) {
            console.log(e);
        }
    };
}

// **** UPDATE_ITEM **** 
export function updateItem(id, object) {
    return async (dispatch) => {
        try {
            return dispatch({
                type: actionTypes.UPDATE_ITEM,
                payload: {
                    id: id,
                    value: object
                }
            });
        }
        catch (e) {
            console.log(e);
        }
    };
}

// **** CLEAR POSTAL CODE ****

export function clearPostalCode() {
    return async (dispatch) => {
        try {
            return dispatch({ type: actionTypes.CLEAR_POSTAL_CODE })
        }
        catch (e) {
            console.log(e)
        }
    }
}


// GET PEDIDOS CLIENTE LOGEADO
export function getClientPedidos(UUID, EMAIL) {
    return async (dispatch) => {
        try {
            const response = await http.get(`/apikuo-pri/pedidos.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`);
            return dispatch({ type: actionTypes.GET_PEDIDOS_USUARIO_LOGUEADO, payload: response.data });
        }
        catch (e) {
            console.log(e);
        }
    };
}

// POST NUEVO PEDIDO | USUARIO LOGEADO
export function postPedidoUsuarioLogeado(UUID, EMAIL, BODY_OBJ) {
    return async (dispatch) => {
        const endpoint = "/apikuo-pri/pedidos.xsjs";
        try {
            // Limpieza del objeto BODY
            const body = { ...BODY_OBJ };
            delete body.TOKEN_TARJETA;

            const jsonBody = JSON.stringify(body);
            dispatch(postLog(crearLog("REQUESTING", body.DATOS_CLIENTE.EMAIL, endpoint, jsonBody)));

            dispatch(setLoader(true));
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: `/apikuo-pri/pedidos.xsjs?UUID=${UUID}&EMAIL=${EMAIL}`,
                url: endpoint,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode = block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                },
                data: JSON.stringify(BODY_OBJ)
            }

            const response = await http.request(config)

            if (!response.data.error) {
                const result = { ...response.data };
                delete result.data?.TOKEN_TARJETA;
                delete result.data?.RESPUESTA_AUTHORIZE?.card;

                dispatch(postLog(crearLog("SUCCESS", body.DATOS_CLIENTE.EMAIL, endpoint, JSON.stringify(result))));                
                dispatch(resetFormCheckout());

                // Muestra mensaje de éxito
                toast.success('¡Pedido realizado correctamente!', {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 3000,
                    theme: 'dark'
                });
                dispatch(setLoader(false))
                dispatch(setPedidoRealizado(true))
                if (BODY_OBJ && BODY_OBJ.DIRECCION && BODY_OBJ.DIRECCION.CREAR) {
                    dispatch({ type: actionTypes.ADD_CLIENT_DIR, payload: BODY_OBJ.DIRECCION })
                }
                dispatch({ type: actionTypes.POST_PEDIDO_USUARIO_LOGEADO, payload: response.data.data })
            }
            else {
                const result = { ...response.data };
                delete result.data?.TOKEN_TARJETA;
                delete result.data?.RESPUESTA_AUTHORIZE?.card;

                dispatch(postLog(crearLog("ERROR", body.DATOS_CLIENTE.EMAIL, endpoint, JSON.stringify(result))));

                // Muestra mensaje de error
                dispatch(setLoader(false))
                dispatch(setPedidoRealizado(false))
                toast.error('Error al realizar tu pedido.', {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 3000,
                    theme: 'dark'
                });
                console.log(response.data)
            }
            // return response
        }
        catch (error) {
            dispatch(postLog(crearLog("ERROR", BODY_OBJ.DATOS_CLIENTE.EMAIL, endpoint, JSON.stringify(error))));

            // Muestra mensaje de error
            dispatch(setLoader(false))
            dispatch(setPedidoRealizado(false))
            toast.error('Error al realizar tu pedido.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });
            console.log(error)
        }
    }
}

// POST_PEDIDO_USUARIO_NO_LOGEADO

export function postPedidoUsuarioNoLogueado(BODY_OBJ) {
    return async (dispatch) => {
        const endpoint = "/apikuo-public/pedidos.xsjs";
        try {
            // Limpieza del objeto BODY
            const body = { ...BODY_OBJ };
            delete body.TOKEN_TARJETA;

            const jsonBody = JSON.stringify(body);
            dispatch(postLog(crearLog("REQUESTING", body.DATOS_CLIENTE.EMAIL, endpoint, jsonBody)));
            
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `/apikuo-public/pedidos.xsjs?`,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode = block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
                },
                data: JSON.stringify(BODY_OBJ)
            }
            const response = await http.request(config)

            if (!response.data.error) {
                const result = { ...response.data };
                delete result.data?.TOKEN_TARJETA;
                delete result.data?.RESPUESTA_AUTHORIZE?.card;

                dispatch(postLog(crearLog("SUCCESS", body.DATOS_CLIENTE.EMAIL, endpoint, JSON.stringify(result))));                
                dispatch(resetFormCheckout());

                // Muestra mensaje de éxito
                toast.success('¡Pedido realizado correctamente!', {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 3000,
                    theme: 'dark'
                });
                localStorage.removeItem('cartItems');
                localStorage.removeItem('TOTAL');
                dispatch(setLoader(false))
                dispatch(setPedidoRealizado(true))
                return dispatch({ type: actionTypes.POST_PEDIDO_USUARIO_NO_LOGEADO, payload: response.data.data });
            } else {
                const result = { ...response.data };
                delete result.data?.TOKEN_TARJETA;
                delete result.data?.RESPUESTA_AUTHORIZE?.card;

                dispatch(postLog(crearLog("ERROR", body.DATOS_CLIENTE.EMAIL, endpoint, JSON.stringify(result))));

                // Muestra mensaje de error
                dispatch(setLoader(false))
                dispatch(setPedidoRealizado(false))
                toast.error('Error al realizar tu pedido.', {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 3000,
                    theme: 'dark'
                });
                console.log(response.data)
            }
            // return response
        }
        catch (error) {
            dispatch(postLog(crearLog("ERROR", BODY_OBJ.DATOS_CLIENTE.EMAIL, endpoint, JSON.stringify(error))));

            // Muestra mensaje de error
            dispatch(setLoader(false))
            dispatch(setPedidoRealizado(false))
            toast.error('Error al realizar tu pedido.', {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000,
                theme: 'dark'
            });
            console.log(error)
        }
    }
}

// GET_PRODUCTOS_ULTIMO_PEDIDO
export function getProductosUltimoPedido(ID_PEDIDO) {
    return async (dispatch) => {
        try {
            const response = await http.get(`/apikuo-public/productoshistoricos.xsjs?ID_PEDIDO=${ID_PEDIDO}`);
            return dispatch({ type: actionTypes.GET_PRODUCTOS_ULTIMO_PEDIDO, payload: response.data });
        } catch (e) {
            console.log(e);
        }
    };
}


// SET LOADER
export function setLoader(boolean) {
    return { type: actionTypes.SET_LOADER, payload: boolean }
}


// SET PEDIDO REALIZADO
export function setPedidoRealizado(boolean) {
    return { type: actionTypes.SET_PEDIDO_REALIZADO, payload: boolean }
}

// Estandarización para crear logs
const crearLog = (estado, usuario, endpoint, jsonBody) => ({
    APP: "selectoskeken",
    USUARIO: usuario,
    ENDPOINT: endpoint,
    HTTP_REQUEST: "POST",
    JSON_BODY: jsonBody.substring(0, 4999),
    ESTADO: estado
});

// POST_LOG
export function postLog(BODY_OBJ) {
    return async () => {
        try {
            await http.post("/apikuo-public/logs.xsjs", BODY_OBJ, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'X-XSS-Protection': '1; mode=block',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Frame-Options': 'SAMEORIGIN',
                    'Content-Security-Policy': 'self',
                    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
                },
            });
        } catch (error) {
            console.error("Error al guardar log:", error);
        }
    };
}